import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/personDeviceRelation/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/personDeviceRelation/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/personDeviceRelation/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/personDeviceRelation/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/personDeviceRelation/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/personDeviceRelation/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/personDeviceRelation/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function isUnbindDeviceList(formData) {
  return request.post(constant.serverUrl + "/personDeviceRelation/isUnbindDeviceList", formData);
}

function batchBindDevice(formData) {
  //多设备绑定人
  return request.post(constant.serverUrl + "/personDeviceRelation/batchBindDevice", formData);
}

function batchBindPerson(formData) {
  //多人员绑定设备
  return request.post(constant.serverUrl + "/personDeviceRelation/batchBindPerson", formData);
}

function batchUnBindPerson(formData) {
  //多人员绑定设备
  return request.post(constant.serverUrl + "/personDeviceRelation/batchUnBindPerson", formData);
}

function unbindDevice(formData) {
  return request.post(constant.serverUrl + "/personDeviceRelation/unbindDevice", formData);
}

function devicePersonList(formData) {
  //设备绑定人员列表
  return request.post(constant.serverUrl + "/personDeviceRelation/devicePersonList", formData);
}

function devicePersonSync(formData) {
  //数据同步-单个
  return request.post(constant.serverUrl + "/personDeviceRelation/devicePersonSync", formData);
}


function devicePersonsSync(formData) {
  //数据同步-批量
  return request.post(constant.serverUrl + "/personDeviceRelation/devicePersonsSync", formData);
}

function updateIsWrite(formData) {
  //更新用户图片是否保存在设备上
  return request.post(constant.serverUrl + "/personDeviceRelation/updateIsWrite", formData);
}


function updatePersonIsWrite(formData) {
  //更新单个用户图片状态
  return request.post(constant.serverUrl + "/personDeviceRelation/updatePersonIsWrite", formData);
}

function devicesPersonSync(formData) {
  //数据同步-多设备单用户
  return request.post(constant.serverUrl + "/personDeviceRelation/devicesPersonSync", formData);
}


function unbindSelectDevice(formData) {
  //解除选择的设备绑定
  return request.post(constant.serverUrl + "/personDeviceRelation/unbindSelectDevice", formData);
}


export default {
  create, edit, add, update, remove, batchRemove, pageList,
  isUnbindDeviceList, batchBindDevice, unbindDevice, batchBindPerson, batchUnBindPerson,
  devicePersonList, devicePersonSync, devicePersonsSync, updateIsWrite, updatePersonIsWrite,
  devicesPersonSync,unbindSelectDevice
}